<!--8个人中心-16合同管理-2合同上传(1经理)-->
<template>
  <div class="agree-page">
    <div class="agree_main">
      <!--agree_upper start-->
      <div class="agree_upper">
        <div class="upper_tit">我的上传</div>
        <el-button class="agree_upload_btn" @click="$refs.uploadPop.open()">上传合同</el-button>
      </div>
      <!--agree_upper end-->
      <!--agree-head start-->
      <div class="agree-head">
        <div class="key_page_select">
          <div class="select_item">
            <div class="label">领取时间:</div>
            <div class="select">
              <el-date-picker
                  v-model="value1"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div class="select_item">
            <div class="label">归还时间:</div>
            <div class="select">
              <el-date-picker
                  v-model="value2"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </div>
          </div>
          <div class="select_item">
            <div class="label">上传类型:</div>
            <div class="select">
              <el-select v-model="value" placeholder="请选择" size="160px">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
      <!--agree-head end-->
      <!--agree-body start-->
      <div class="record-body">
        <div class="record_table">
          <!--record_tr start-->
          <div class="record_tr" v-for="(item, index) in 10" :key="index">
            <div class="record_td">
              <div class="td">
                <span class="color-9">上传类型：</span>已签约
              </div>
              <div class="td">
                <span class="color-9">签约人：</span>刘晓静
              </div>
            </div>
            <div class="record_td">
              <div class="td">
                <span class="color-9">合同编号：</span>1-20
              </div>
              <div class="td">
                <span class="color-9">工号：</span>001
              </div>
            </div>
            <div class="record_td">
              <div class="td">
                <span class="color-9">领取时间：</span>2021-1-08 14:14
              </div>
              <div class="td">
                <span class="color-9">归还时间：</span>2021-1-08 14:14
              </div>
            </div>
            <div class="record_td">
              <div class="agree_a"><router-link class="agree_links" to="/personage/AgreeDetail">查看详情>></router-link></div>
            </div>
          </div>
          <!--record_tr end-->
        </div>
        <div class="paging">
          <el-pagination
              background
              layout="prev, pager, next, jumper, ->, total"
              :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 合同详情弹出 S -->
    <upload-agree-pop ref="uploadPop"></upload-agree-pop >
    <!-- 合同详情弹出 E -->
  </div>
</template>

<script>
import UploadAgreePop from '../../components/UploadAgreePop'
export default {
  components: { UploadAgreePop},
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
    };
  },
};
</script>

<style scoped lang="less">
.key_page_select{
  display:flex;align-items:center;flex-wrap:wrap;margin:0 -10px;
  .select_item{display:flex;font-size:14px;color:#666;align-items:center;padding:10px 0px;margin:0 10px;}
  .label{margin-right:10px;white-space:nowrap;width:80px;text-align:right;}
  .select{width:200px;}
  /deep/ .el-input__inner{border:1px solid #ccc;border-radius:0;}
}
.agree_a{color:#3273F6;cursor:pointer;}
.agree_links{color:#3273F6;}
/deep/.key_page_select .el-date-editor.el-input, .el-date-editor.el-input__inner{width:200px;}
.agree_main{background:#fff;padding-bottom:30px;}
.agree-head{margin:0 20px;padding:20px 0px;border-bottom:1px solid #EAEAEA;}
.record_table{display:table;width:100%;margin:20px 0px;vertical-align: middle;}
.record_tr{display:table-row;vertical-align: middle;}
.record_td{display:table-cell;padding:15px 30px;font-size:16px;line-height:1.8;vertical-align: middle;}
.record_td .td{line-height:1.8}
.record_tr:nth-child(2n){background: #F7FCFF}
.agree_upper{
  display:flex;justify-content:space-between;align-items:center;padding:20px 30px 0 30px;
  .upper_tit{font-size:18px;position:relative;padding-left:10px;}
  .upper_tit:before{width:4px;height:20px;border-radius:2px;position:absolute;left:0;top:50%;margin-top:-10px;display:block;content:"";background:#3273F6;}
}
.agree_upload_btn{width:100px;height:36px;border-radius:4px;background:#3273F6;font-size:16px;color:#fff;text-align:center;line-height:36px;padding:0px;outline:none;}
</style>